@import 'styles/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-outer-bg;
  min-height: 200px;
  line-height: 1.3;
}

a,
a:hover,
a:visited {
  color: $color-highlight;
}

h3 {
  color: $color-highlight;
  font-size: 100%;
  margin-bottom: 5px;
}

h4 {
  margin-bottom: 5px;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 800ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 800ms;
}

.innercontent {
  margin: $margin-outer;
}

hr {
  border: 0;
  border-bottom: 1px solid $color-border;
}
