@import '../../styles/variables';

.skills {
  .skillcloud {
    width: 100%;
    height: calc(100vh - 250px);
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
