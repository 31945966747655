@import '../../styles/variables';

.mywork {
  .projects {
    .vertical-timeline-element-content {
      background-color: $color-outer-bg;
      -webkit-box-shadow: 0 3px 0 $color-highlight;
      box-shadow: 0 3px 0 $color-highlight;
    }
    .projectdetailtitle {
      font-weight: bold;
    }
  }
}
