@import '../../styles/variables';

.home {
  background-color: $color-bg;
  margin: auto;
  display: grid;
  grid-template-columns: calc(($margin-default * 2) + 20px) 0 auto;

  position: relative;
  .content {
    grid-column-start: 3;
    grid-column-end: 3;
    overflow-y: auto;
  }
}

@media (min-width: $breakpoint-1) {
  .home {
    height: calc(100vh - 60px);
    margin: $margin-outer;
    grid-template-columns: calc(($margin-default * 2) + 20px) 200px auto;
    transition: opacity 2s, width 2s, left 2s, font-size 2s, color 2s;
  }
}
