@import '../../styles/variables';

.header {
  display: grid;
  grid-template-columns: auto 50px;
  height: 50px;
  background-color: $color-bg;
  border-bottom: 1px solid $color-border;

  h2 {
    margin: 10px;
    margin-left: $margin-outer;
    color: $color-highlight;
  }
  .smallimage {
    height: 42px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    margin: 4px;
    border: 1px solid $color-border;
  }
}

@media (min-width: $breakpoint-1) {
  .header {
    display: none;
  }
}
