@import '../../styles/variables';

.menu {
  grid-column-start: 1;
  grid-column-end: 1;
  border-right: 1px solid $color-border;

  ul {
    list-style: none;
    padding-inline-start: 0;

    li {
      font-size: 120%;
      margin: $margin-default;
      a {
        color: $color-link;
        &:hover {
          color: $color-link-hover;
        }
      }
      &:hover {
        font-weight: bold;
      }
    }

    .selected {
      color: $color-link-hover;
    }
  }

  .social {
    position: absolute;
    bottom: 0;
    margin-left: 12px;
    margin-bottom: 10px;
    .social-xing {
      img {
        height: 25px;
      }
    }
    .social-linkedin {
      img {
        height: 25px;
      }
    }
  }
}
