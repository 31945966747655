@import '../../styles/variables';

.imageblock {
  display: none;
}

@media (min-width: $breakpoint-1) {
  .imageblock {
    display: inline;
    grid-column-start: 2;
    grid-column-end: 2;
    height: calc(100% + 40px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: -20px;

    box-shadow: 0 3px 50px rgb(0 0 0 / 0.2);

    .name {
      position: absolute;
      bottom: 0;
      color: $color-highlight;
      font-size: 180%;
      font-weight: bold;
      text-align: center;
      width: 200px;
    }
  }
}
