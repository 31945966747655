@import '../../styles/variables';

.loadercomponent {
  height: calc(100vh - 60px);
  position: relative;
}

.circle {
  position: absolute;
  border: 3px solid transparent;
  border-top-color: $color-highlight;
  border-radius: 50%;
  -webkit-animation: rotate linear infinite;
  animation: rotate linear infinite;
}
.circle.one {
  height: 50px;
  width: 50px;
  left: 50px;
  top: 50px;
  -webkit-animation-duration: 0.85s;
  animation-duration: 0.85s;
}
.circle.two {
  height: 75px;
  width: 75px;
  top: 38px;
  left: 38px;
  -webkit-animation-duration: 0.95s;
  animation-duration: 0.95s;
}
.circle.three {
  height: 100px;
  width: 100px;
  top: 25px;
  left: 25px;
  -webkit-animation-duration: 1.05s;
  animation-duration: 1.05s;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -90px 0 0 -90px;
}
body {
  background-color: #1a1a1a;
}
@-webkit-keyframes rotate {
  from {
    transform: rotateZ(360deg);
  }
  to {
    transform: rotateZ(0deg);
  }
}
@keyframes rotate {
  from {
    transform: rotateZ(360deg);
  }
  to {
    transform: rotateZ(0deg);
  }
}
