$color-outer-bg: #ececec;
$color-bg: #fff;
$color-text: #000;
$color-text2: #fff;
$color-link: #929292;
$color-link-hover: #00a2db;
$color-debug: red;
$color-border: #ececec;
$color-highlight: #00a2db;

$margin-outer: 30px;
$margin-default: 15px;

$breakpoint-1: 600px;
$breakpoint-2: 800px;

$animation: 0.5s;
