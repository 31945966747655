@import '../../styles/variables';

.aboutme {
  .topics {
    display: flex;
    flex-wrap: wrap;
    .topic {
      display: flex;
      text-align: center;
      width: 100%;

      h4 {
        margin-top: 5px;
        margin-bottom: 10px;
      }
      .icon {
        color: $color-highlight;
        font-size: 40px;
      }
      p {
        padding: 5px;
      }
    }
  }

  .jobs {
    .vertical-timeline-element-content {
      background-color: $color-outer-bg;
      -webkit-box-shadow: 0 3px 0 $color-highlight;
      box-shadow: 0 3px 0 $color-highlight;
    }
  }
}

@media (min-width: $breakpoint-2) {
  .aboutme {
    .topic {
      width: 50% !important;
      p {
        text-align: left;
      }
    }
  }
}
